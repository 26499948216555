import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/ctadisclosure.module.scss"
class CtaDisclosure extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {}});
  }
  componentDidMount(){
    if(this.props.data){
      this.state = {
        data: this.props.data
      }
    }
  }
  render() {
    let browser = null;
    if(isChrome()){
      browser = 'chrome';
    }
    else if(isFirefox()){
      browser = 'firefox';
    }
    else{
      browser = 'other';
    }
    return (
        <div id = {Styles.ctadisclaimer}>
          {(() => {
            switch (browser) {
              case 'chrome':
                return <p>This free extension will update this browser's search engine to one powered by Yahoo!. By continuing, you acknowledge the <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Terms of Service</a> and <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Privacy Policy</a>, and that you may be presented with more optional offers.</p>;
                break;
              case 'firefox':
                return <p>This free extension will update this browser's search settings. By continuing, you acknowledge the <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Privacy Policy</a> and <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Terms of Service</a>, and that you may be presented with more optional offers.</p>;
                break;
              default:
              	return <p>By clicking Download, you agree to the <a href="/terms-of-service/" target="_blank" className={Styles.bold} >Terms of Service</a> and <a href="/privacy-policy/" target="_blank" className={Styles.bold} >Privacy Policy</a>.</p>;
            }
          })()}
        </div>
    )
  }
}
export default CtaDisclosure
