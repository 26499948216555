import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmaWave from "src/components/nonbmaWavefin"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import CtaDisclosure from "src/components/ctadisclosure"

const wavelpData = import("./data/data.json");


export default function bmTideSpb() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
      #ctadisclosure-module--ctadisclaimer {
        color: #666;
        width: 50%;
        margin: 0 auto;
        line-height: 150%;
        font-size: 12px;
      }

      .wave-module--wave h1 {
        font-size: 24px;
      }

       .footerlinks-module--br {
         display: none!important;
       }

       .wave-module--wave .wave-module--header img {
         padding: 8px 0 8px 8px;

       }

       .wave-module--wave .wave-module--center {
         width: 100%;
       }

       .wave-module--wave ul.wave-module--bullets li {
         padding-bottom: 10px;
         flex: 0 1 45%!important;
       }

       .wave-module--wave ul.wave-module--bullets {
         width: 100%;
    max-width: 850px;
       }

       .wave-module--wave button.wave-module--waveBtn{
       	animation: 1.5s normal 0.5s 1;
        animation-name: slideInFromLeft;
       	animation-fill-mode: forwards;

       }
       @keyframes slideInFromLeft {
       	from {
       		background-position: right bottom;
       	}
       	to {
       		background-position: 0 0;
       		background-color: #096bfb;
       	}
       }

       .wave-module--wave button.wave-module--waveBtn {
         padding: 25px;
         width: 500px;
       }
       .wave-module--wave .wave-module--stepGrid {
        line-height:135%;
       }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>PDFtoDOC New Tab - frompdftodoc.com</title></Helmet>
    <section>
      <NonbmaWave data={wavelpData}>
      <CtaDisclosure></CtaDisclosure></NonbmaWave>
      </section>
    </HomepageLayout>
  )
}
